import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import Feedback from '../../../components/Feedback';
import * as serviceWorker from '../../../serviceWorker';
import { amplifyConfig } from './aws-amplify-config';

Amplify.configure(await amplifyConfig());

if (document.getElementById('feedback')) {
    ReactDOM.createRoot(document.getElementById('feedback')).render(
        <React.StrictMode>
            <BrowserRouter>
                <Feedback />
            </BrowserRouter>
        </React.StrictMode>
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
