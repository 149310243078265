import { post } from 'aws-amplify/api';

/**
 * Sends Q&A and/or feedback to SNS to be emailed
 * @param {object} feedback collection of contact info
 * @returns {Promise} promise which resolves to the SNS response
 */
export const sendFeedback = async (feedback) => {
    return post('stitchz-web-api', '/feedback', {
        headers: {
        },
        body: feedback
    });
};

/**
 * Sends verifying token to Google for confirmation
 * @param {object} code collection of contact info
 * @returns {Promise} promise which resolves to the SNS response
 */
export const verifyGoogleReCaptcha = async (code) => {
    return post('stitchz-web-api', '/feedback/verify', {
        headers: {
        },
        body: code
    });
};
